var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$auth.permissions.admin.is || _vm.$auth.permissions.prescreenings.add)?_c('q-card',{staticStyle:{"max-width":"1500px"}},[_c('loader',{attrs:{"retry":_vm.initialize}},[(_vm.prescreeningForm)?_c('q-form',{ref:"outerForm",attrs:{"autocorrect":"off","autocapitalize":"off","autocomplete":"off","spellcheck":"false","greedy":"","no-error-focus":""},on:{"submit":function($event){$event.preventDefault();return _vm.proceedWithAction($event)}}},[_c('q-card-section',{staticClass:"title"},[_c('div',{staticClass:"text-h6"},[_vm._v("Prescreening")])]),_c('q-separator'),_c('q-card-section',[_c('div',{staticClass:"col q-px-md",domProps:{"innerHTML":_vm._s(_vm.prescreeningForm.description)}}),_c('div',{staticClass:"col"},[_c('google-map',{attrs:{"name":'Google Map',"width":'100%',"height":'400px'},on:{"position":function (e) { return this$1.emmitedPosition = e; }}})],1)]),_c('q-separator'),_c('q-card-section',[_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('q-card-section',[_vm._v(" Initial Questionnaire ")]),_c('q-separator'),_c('q-card-section',[_c('dynamic-form',{ref:"prescreeningForm",attrs:{"answers":_vm.answers,"questionnaire":_vm.prescreeningForm},on:{"update:answers":function($event){_vm.answers=$event}}})],1)],1)],1),_c('q-separator',{attrs:{"spaced":""}}),_c('q-card-section',{staticClass:"q-mt-md"},[_c('div',{staticClass:"row q-mb-md"},[_c('div',{staticClass:"col-12"},[_c('div',[_c('p',[_vm._v(" Is that person eligible for at least one of our projects? ")])]),_c('div',[_c('q-field',{attrs:{"borderless":"","color":"black","rules":[_vm.$rules.required]},model:{value:(_vm.isPersonEligibleForAtLeastOneProject),callback:function ($$v) {_vm.isPersonEligibleForAtLeastOneProject=$$v},expression:"isPersonEligibleForAtLeastOneProject"}},[_c('q-option-group',{staticStyle:{"display":"flex","flex-wrap":"wrap"},attrs:{"inline":"","options":[
                      {
                        value: true,
                        label: 'Yes'
                      },
                      {
                        value: false,
                        label: 'No'
                      }
                    ],"color":"primary"},model:{value:(_vm.isPersonEligibleForAtLeastOneProject),callback:function ($$v) {_vm.isPersonEligibleForAtLeastOneProject=$$v},expression:"isPersonEligibleForAtLeastOneProject"}})],1)],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-caption q-ml-sm"},[_vm._v("Comment")]),_c('q-editor',{model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)]),_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('q-card-section',[_vm._v(" Action Taken ")]),_c('q-separator'),_c('q-card-section',[_c('q-select',{attrs:{"filled":"","options":_vm.actions,"option-label":"title","option-value":"id","label":"Select Action"},model:{value:(_vm.actionToTake),callback:function ($$v) {_vm.actionToTake=$$v},expression:"actionToTake"}})],1),_c('q-separator'),_c('q-card-section',[(
                  _vm.actionToTake &&
                    _vm.actionToTake.id &&
                    _vm.actionToTake.id == 'referralAndArchive'
                )?_c('q-input',{attrs:{"filled":"","label":"External Referral"},model:{value:(_vm.externalReferral),callback:function ($$v) {_vm.externalReferral=$$v},expression:"externalReferral"}}):_vm._e()],1),_c('q-card-actions',{attrs:{"align":"right"}},[(_vm.actionToTake)?_c('q-btn',{attrs:{"flat":"","label":"Proceed with action","type":"submit","loading":_vm.saving}}):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1):_vm._e(),_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.assignTo),callback:function ($$v) {_vm.assignTo=$$v},expression:"assignTo"}},[_c('q-card',{staticStyle:{"min-width":"400px"}},[_c('q-card-section',{staticStyle:{"text-align":"center"}},[_vm._v(" Your prescreening ID is"),_c('br'),_c('strong',{staticStyle:{"font-size":"30px"}},[_vm._v(_vm._s(_vm.prescreeningId))])]),_c('q-separator'),_c('q-card-section',[_c('div',{staticClass:"row items-center q-col-gutter-sm"},[_c('div',{staticClass:"col-auto"},[_vm._v("Assign to a person:")]),_c('div',{staticClass:"col"},[_c('entity-select',{attrs:{"provider":_vm.$service.providers.users,"filled":"","optionLabel":function (i) { return i && i.id
                    ? ((i.firstName) + " " + (i.lastName ? i.lastName : ''))
                    : 'None'; }},model:{value:(_vm.assignUser),callback:function ($$v) {_vm.assignUser=$$v},expression:"assignUser"}})],1)])]),_c('q-separator'),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{attrs:{"disable":_vm.submittingPrescreening,"label":"Cancel","flat":""},on:{"click":function($event){_vm.assignTo = false}}}),_c('q-btn',{attrs:{"loading":_vm.submittingPrescreening,"label":"OK","color":"primary"},on:{"click":function($event){_vm.submitAndNavigate(function () { return ({ path: '/' }); })}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }