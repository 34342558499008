























































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import { PrescreeningActionTaken } from "@/data/models/PrescreeningActionTaken";
import { Project } from "@/data/models/Projects";
import {
  NewQuestionnaireSubmission,
  Prescreening,
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";
import { User } from "@/data/models/Users";
import { Guid } from "guid-typescript";
import { toArray } from "@/lib/answers";
import GoogleMap from "@/components/GoogleMap.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    DynamicForm,
    EntitySelect,
    GoogleMap
  }
})
export default class PrescreeningView extends Vue {
  @Prop()
  public id!: string;
  private isPersonEligibleForAtLeastOneProject = false;
  private comment = "";
  private prescreeningForm: any = null;
  private prescreeningSubmission: any = null;
  private assignUser: any = {};
  private searching = false;
  private submittingPrescreening = false;
  private searchString = "";
  private blocklist = ["1234", "2345"];
  private found = false;
  private notFound = false;
  private assignTo = false;
  private intake = false;
  private consentFile: any = null;
  private assignToUsers: any[] = [];
  private answers: any[] = [];
  private saving = false;
  private emmitedPosition: any = null;
  private consent: any = {
    informational: false,
    promotional: false,
    share: false
  };
  private prescreeningId: any = "";

  private externalReferral = "";
  private actionToTake: any = null;
  private prescreeningActionIds: any = {};
  private actions: any[] = [
    {
      id: "intake",
      title: "Undertaking the case management"
    },
    {
      id: "assignTo",
      title: "Undertaking the case management in a future time"
    },
    {
      id: "archived",
      title: "Information provision and no other action. The case is archived"
    },
    {
      id: "referralAndArchive",
      title: "Information provision and External Referral"
    }
  ];
  validateForm() {
    const form = this.$refs.outerForm as any;
    return form.validate(true);
  }
  async proceedWithAction() {
    this.saving = true;
    this.prescreeningId = this.createUniqueId();
    try {
      switch (this.actionToTake.id) {
        case "intake": {
          await this.submitAndNavigate(() => ({
            name: "IntakeForm",
            params: { prescreeningId: this.prescreeningSubmission.id }
          }));
          break;
        }
        case "assignTo": {
          this.proceedWithAssignTo();
          break;
        }
        case "archived": {
          await this.submitAndNavigate(() => ({ path: "/" }));
          break;
        }
        case "referralAndArchive": {
          await this.submitAndNavigate(() => ({ path: "/" }));
          break;
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.saving = false;
  }
  async submitAndNavigate(loc: () => any) {
    const valid = await this.validateForm();
    if (valid) {
      try {
        await this.submitPrescreening();
        //debugger;
        this.$router.push(loc());
      } catch (err) {
        console.error(err);
      }
    }
  }
  proceedWithAssignTo() {
    this.validateForm().then((valid: any) => {
      if (valid) {
        this.assignTo = true;
      }
    });
  }
  createUniqueId() {
    let result = "";
    const length = 8;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  async submitPrescreening() {
    const questionnaireSubmission = NewQuestionnaireSubmission(
      this.prescreeningForm.id,
      this.answers
    );
    const action = this.actionToTake;
    const actionIds = this.prescreeningActionIds;
    const actionId = actionIds[action.id];
    this.prescreeningSubmission = {
      id: Guid.create().toString(),
      assignedToUser: null,
      householdMember: null,
      prescreeningId: this.prescreeningId,
      questionnaireSubmission: questionnaireSubmission,
      prescreeningActionTakenId: actionId,
      isPersonEligibleForAtLeastOneProject: this
        .isPersonEligibleForAtLeastOneProject,
      comment: this.comment,
      mapLong: this.emmitedPosition ? this.emmitedPosition.lng : null,
      mapLat: this.emmitedPosition ? this.emmitedPosition.lat : null
    };
    if (this.assignUser && this.assignUser != null && this.assignUser.id) {
      this.prescreeningSubmission.assignedToUserId = this.assignUser.id;
    }
    for (const ans of this.prescreeningSubmission.questionnaireSubmission
      .answers) {
      ans.questionnaireSubmissionId = this.prescreeningSubmission.questionnaireSubmission.id;
    }
    try {
      this.submittingPrescreening = true;
      await this.$service.crud.prescreenings.addAsync([
        this.prescreeningSubmission
      ]);
    } catch (err) {
      this.$q.notify({ message: err, color: "red-4" });
      console.error(err);
      throw err;
    } finally {
      this.submittingPrescreening = false;
    }
  }
  async initialize() {
    const res = await this.$service.providers.questionnaires.fetchItemsAsync({
      columnFilters: [
        { column: "IsActivePrescreening", value: `true`, op: "Equals" }
      ]
    });
    if (res.items && res.items.length > 0) {
      this.prescreeningForm = await this.$service.providers.questionnaires.fetchItemAsync(
        res.items[0].id
      );
    }
    const prescreeningActionsRes = await this.$service.providers.prescreeningActionTaken.fetchItemsAsync();
    const prescreeningActionIds: any = {};
    if (prescreeningActionsRes && prescreeningActionsRes.items) {
      prescreeningActionsRes.items.forEach(function(prescreeningAction) {
        prescreeningActionIds[prescreeningAction.value] = prescreeningAction.id;
      });
    }
    this.prescreeningActionIds = prescreeningActionIds;
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async search() {
    this.notFound = false;
    this.found = false;
    if (!this.searchString) {
      return;
    }
    this.searching = true;
    try {
      const res = await this.$service.providers.prescreenings.fetchItemsAsync({
        columnFilters: [
          {
            column: "PrescreeningId",
            value: `'${this.searchString}'`,
            op: "Equals"
          }
        ]
      });
      if (res.items && res.items.length > 0) {
        this.found = true;
        this.prescreeningSubmission = res.items[0];
      } else {
        this.notFound = true;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.searching = false;
    }
    this.searching = false;
  }
}
